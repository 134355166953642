<template>
  <div class="annex">
    <div v-loading="loading">
      <div style="float: right; color: red" v-if="!disabled">
        根目录创建功能：
        <el-button type="success" size="small" icon="el-icon-upload" @click="onFileAdd(true)"
          >新建文件</el-button
        >
        <el-button
          type="primary"
          size="small"
          style="background-color: #6391f3c2"
          icon="el-icon-upload"
          @click="onFileAddDirectory(true)"
          >新建文件夹</el-button
        >
        <el-button type="primary" size="small" icon="el-icon-upload" @click="onFileUpload('')"
          >导入压缩包</el-button
        >
      </div>
      <div class="file-content">
        <div class="file-left">
          <div style="color: #000">目录</div>
          <el-tree
            class="deptTree"
            highlight-current
            :data="treeData"
            :props="defaultProps"
            :render-content="renderContent"
            @node-expand="handleNodeExpand"
            @node-click="handleNodeClick"
            node-key="id"
            ref="tree"
          >
          </el-tree>
        </div>
        <div class="file-right">
          <div v-if="isStatistics && !disabled" style="display: flex">
            <div>
              <i class="iconfont el-icon-folder-opened" style="color: #ffe78f; font-size: 60px"></i>
            </div>
            <div style="width: 100%">
              <div style="color: #000; font-size: 19px">{{ fileName }}</div>
              <div style="display: flex; justify-content: space-between">
                <div style="font-size: 15px">{{ documentNum }}个文档 / {{ folderNum }}个文件夹</div>
                <div>
                  <el-button
                    type="text"
                    size="small"
                    class="text_adddocument_Bgc"
                    @click.stop="onFileAdd(false, atPresentlInfo)"
                    >新增文件</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    class="text_addFile_Bgc"
                    @click.stop="onFileAddDirectory(false, atPresentlInfo)"
                    >新增文件夹</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    class="text_addZip_Bgc"
                    @click.stop="onFileUpload(atPresentlInfo.id)"
                    >导入压缩包</el-button
                  >
                  <el-popover placement="bottom" width="200" trigger="hover">
                    <el-button
                      type="text"
                      size="small"
                      class="text_download_Bgc"
                      v-loading="downloadLoading"
                      @click.stop="onFfileDownloadFolder(atPresentlInfo)"
                      >下载</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      class="text_Edit_Bgc"
                      @click.stop="onFileEdit(atPresentlInfo)"
                      >重命名</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      class="text_Remove_Bgc"
                      @click.stop="onFileDelete(false, atPresentlInfo)"
                      >删除</el-button
                    >
                    <span slot="reference" class="more">更多</span>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
          <div>
            <el-table
              ref="table"
              row-key="id"
              border
              tooltip-effect="dark"
              class="multipleTable"
              :data="tableData"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @row-click="rowClick"
              @expand-change="expandChange"
            >
              <el-table-column align="left" prop="fileName" label="文件名称" min-width="300">
                <template slot-scope="scope">
                  <template v-if="scope.row.isDirectory">
                    <i
                      class="iconfont el-icon-folder-opened"
                      style="color: #ffe78f; margin-right: 5px"
                    ></i>
                    <span>{{ scope.row.fileName }}</span>
                  </template>
                  <template v-else>
                    <i
                      class="iconfont el-icon-document"
                      style="color: #409eff; margin-right: 5px"
                    ></i>
                    <span>{{ scope.row.fileName }}</span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="staffName"
                align="center"
                label="创建人"
                min-width="110"
              ></el-table-column>
              <el-table-column prop="size" align="center" label="内容大小" min-width="110">
                <template slot-scope="scope">
                  <template v-if="!scope.row.isDirectory"> {{ scope.row.size }}</template>
                </template></el-table-column
              >
              <el-table-column prop="createdDate" align="center" label="创建时间" min-width="120">
                <template slot-scope="scope">
                  {{ scope.row.createdDate | dateFormat }}
                </template>
              </el-table-column>

              <el-table-column label="操作" align="center" width="300" v-if="!disabled">
                <template slot-scope="scope">
                  <template v-if="!scope.row.isDirectory">
                    <el-button
                      type="text"
                      size="small"
                      class="text_download_Bgc"
                      v-loading="downloadLoading"
                      @click.stop="editFile(scope.row)"
                      >编辑文件</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      class="text_download_Bgc"
                      v-loading="downloadLoading"
                      @click.stop="onFileDownload(scope.row)"
                      >下载</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      class="text_Edit_Bgc"
                      @click.stop="onFileEdit(scope.row)"
                      >重命名</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      class="text_Remove_Bgc"
                      @click.stop="onFileDelete(true, scope.row)"
                      >删除</el-button
                    >
                  </template>
                  <template v-if="scope.row.isDirectory">
                    <el-button
                      type="text"
                      size="small"
                      class="text_adddocument_Bgc"
                      @click.stop="onFileAdd(false, scope.row)"
                      >新增文件</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      class="text_addFile_Bgc"
                      @click.stop="onFileAddDirectory(false, scope.row)"
                      >新增文件夹</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      class="text_addZip_Bgc"
                      @click.stop="onFileUpload(scope.row.id)"
                      >导入压缩包</el-button
                    >
                    <el-popover placement="bottom" width="200" trigger="hover">
                      <el-button
                        type="text"
                        size="small"
                        class="text_download_Bgc"
                        v-loading="downloadLoading"
                        @click.stop="onFfileDownloadFolder(scope.row)"
                        >下载</el-button
                      >
                      <el-button
                        type="text"
                        size="small"
                        class="text_Edit_Bgc"
                        @click.stop="onFileEdit(scope.row)"
                        >重命名</el-button
                      >
                      <el-button
                        type="text"
                        size="small"
                        class="text_Remove_Bgc"
                        @click.stop="onFileDelete(true, scope.row)"
                        >删除</el-button
                      >
                      <span slot="reference" class="more">更多</span>
                    </el-popover>
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <EditDialog :isShow.sync="isDialog">
      <template v-slot:title>
        <span style="color: #409eff">{{ isAddfile ? '新增文件夹' : '修改名称' }}</span>
      </template>
      <template v-slot:content>
        <el-form label-width="6em" ref="form" :model="fileInfo">
          <el-form-item
            label="名称"
            prop="fileName"
            :rules="[{ required: true, message: '名称不能为空', trigger: 'blur' }]"
            style="width: 100%"
          >
            <el-input v-model="fileInfo.fileName" placeholder="请输入新的名称"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="success" @click="onSubmitFn">保存</el-button>
        <el-button type="info" plain @click="onCancelFn">取消</el-button>
      </template>
    </EditDialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import axios from 'axios';
export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    businessLines: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      isDialog: false,
      atPresentlInfo: {},
      tableData: [],
      treeData: [],
      fileInfo: { fileName: null, id: null },
      filepath: process.env.VUE_APP_FILEPATH, //文件上传
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'XMDAQDtype',
      },
      type: 'XMDAQD',
      defaultProps: {
        children: 'children',
        label: 'fileName',
      },
      suffix: null,
      fileName: null,
      isAddfile: false,
      downloadLoading: false,
      isStatistics: false,
      renderContent: function (h, { node, data, store }) {
        let addElement = arguments[0]

        // 创建一个带有省略号的 span
        const createEllipsisSpan = label => {
          return addElement(
            'span',
            {
              style: {
                display: 'inline-block',
                maxWidth: '150px', // 根据需要调整最大宽度
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                verticalAlign: 'middle', // 确保文本垂直居中
              },
              attrs: {
                title: label, // 鼠标移入时显示完整内容
              },
            },
            label
          )
        }

        if (data.isDirectory) {
          return addElement('span', [
            addElement('i', {
              class: 'iconfont el-icon-folder-opened',
              style: 'color: #ffe78f; margin-right: 5px; vertical-align: middle;', // 确保图标垂直居中
            }),
            createEllipsisSpan(node.label), // 使用带有省略号的 span
          ])
        } else {
          return addElement('span', [
            addElement('i', {
              class: 'iconfont el-icon-document',
              style: 'color: #409eff; margin-right: 5px; vertical-align: middle;', // 确保图标垂直居中
            }),
            createEllipsisSpan(node.label), // 使用带有省略号的 span
          ])
        }
      },
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      projectFileList: state => state.project.projectFileList,
    }),
    documentNum() {
      let num = 0
      this.tableData.forEach(item => {
        if (!item.isDirectory) {
          num++
        }
      })
      return num
    },
    folderNum() {
      let num = 0
      this.tableData.forEach(item => {
        if (item.isDirectory) {
          num++
        }
      })
      return num
    },
  },
  created() {},
  watch: {
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (options) {
        if (options.id) {
          this.init()
        }
      },
    },
  },

  methods: {
    async editFile(row){
      try {
        const params = {
          filePath: row.filePath,
          fileName: row.fileName,
        }
        const res = await this.$api.project.fileDownload(params)
        const blob = new Blob([res], { type: res.type })
        const fileType = row.fileName.split(".").pop().toLowerCase()

        const formData = new FormData()
        formData.append("fileName", row.fileName)
        formData.append("fileType", fileType)
        formData.append("key", row.id || row.fileName)
        formData.append("blob", blob)
        const uploadResponse = await axios.post('/document/blob-editor-config', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (uploadResponse.code == 200) {
          const editorUrl = `/#/onlyoffice?config=${encodeURIComponent(
            JSON.stringify(uploadResponse.data)
          )}`;
          window.open(editorUrl, "_blank");
        } else {
          this.$message.error("获取配置失败:" + uploadResponse.msg || "获取配置失败")
        }

      } catch (error) {
        this.$message.error('修改失败:' + error)
      }
    },
    // 下载
    async onFfileDownloadFolder(row) {
      this.downloadLoading = true
      try {
        const res = await this.$api.project.fileDownloadFolder(row.id)
        const blob = new Blob([res], { type: 'application/zip' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = row.fileName
        link.click()
        window.URL.revokeObjectURL(link.href)
        this.$message.success('下载成功')
      } catch (err) {
        console.error('下载失败：', err)
        this.$message.error('下载失败，请重试')
      } finally {
        this.downloadLoading = false
      }
    },
    async onFileDownload(row) {
      this.downloadLoading = true
      try {
        const params = {
          filePath: row.filePath,
          fileName: row.fileName,
        }
        const res = await this.$api.project.fileDownload(params)
        const blob = new Blob([res], { type: res.type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = row.fileName
        link.click()
        window.URL.revokeObjectURL(link.href)
        this.$message.success('下载成功')
      } catch (err) {
        console.error('下载失败：', err)
        this.$message.error('下载失败，请重试')
      } finally {
        this.downloadLoading = false
      }
    },
    // 新增文件夹
    async onFileAddDirectory(isRoot = false, row) {
      this.isAddfile = true
      if (isRoot) {
        this.fileInfo = { fileName: null, projectId: this.options.id }
      } else {
        this.fileInfo = { fileName: null, id: row.id }
      }
      this.isDialog = true
    },
    // 新增文件
    async onFileAdd(isRoot = false, row) {
      let input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.style.display = 'none' // 隐藏 input 元素
      document.body.appendChild(input) // 添加到 DOM
      input.addEventListener('change', async e => {
        var files = e.target.files
        const MAX_FILE_SIZE = 1 * 1024 * 1024 * 1024 // 1GB = 1 * 1024 * 1024 * 1024 bytes
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          // 判断文件大小是否超过 1GB
          if (file.size > MAX_FILE_SIZE) {
            this.$message.error(`${file.name} 的文件大小超过了 1GB，请选择一个更小的文件!`)
            document.body.removeChild(input)
            return
          }
          var form = new FormData()
          form.append('file', file)
          if (isRoot) {
            form.append('projectId', this.options.id)
          } else {
            form.append('id', row.id)
          }
          try {
            this.loading = true
            const res = await this.$api.project.projectFileAddFile(form)
            this.init()
            this.loading = false
            this.$message.success('上传成功')
          } catch (error) {
            this.loading = false
            this.$message.error('上传失败')
          }
        }
        document.body.removeChild(input)
      })
      input.click()
    },
    // 删除
    async onFileDelete(isRoot = false, row) {
      this.$confirm(
        `是否删除此${row.isDirectory ? '文件夹' : '文件'}?`,
        `${row.isDirectory ? '文件夹' : '文件'}删除`,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(async () => {
          this.loading = true
          let res = await this.$api.project.projectFiledDlete(row.id)
          if (res.code == 200) {
            if (!isRoot) {
              this.atPresentlInfo = {}
              this.isStatistics = false
            }
            this.init()
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
          }
          this.loading = false
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 编辑文件名称
    async onFileEdit(row) {
      this.isAddfile = false
      if (row.isDirectory) {
        this.suffix = null
        this.fileInfo = { fileName: row.fileName, id: row.id }
      } else {
        const nameArr = row.fileName.split('.') || ['', '']
        this.suffix = nameArr[nameArr.length - 1] || null // 获取文件的后缀名
        this.fileInfo.fileName = nameArr.slice(0, nameArr.length - 1).join('.') // 拼接前面的部分
        this.fileInfo.id = row.id
      }
      this.isDialog = true
    },
    // 提交修改
    onSubmitFn() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          // 校验通过
          this.loading = true
          try {
            let res = null
            if (this.isAddfile) {
              res = await this.$api.project.projectFileAddFiles(this.fileInfo)
              if (res.code == 200) this.$message.success('新增成功')
            } else {
              if (this.suffix) {
                this.fileInfo.fileName = this.fileInfo.fileName + '.' + this.suffix
              }
              res = await this.$api.project.projectFileUpdate(this.fileInfo)
              if (res.code == 200) this.$message.success('修改成功')
            }
            this.init()
            this.loading = false
            this.isDialog = false
          } catch (error) {
            this.loading = false
            this.isDialog = false
          }
        }
      })
    },
    // 取消修改
    onCancelFn() {
      this.fileInfo = { fileName: null, id: null }
      this.isDialog = false
    },
    // 复用上传压缩文件
    async onFileUpload(id = '') {
      let input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.style.display = 'none' // 隐藏 input 元素
      document.body.appendChild(input) // 添加到 DOM

      input.addEventListener('change', async e => {
        var files = e.target.files
        const MAX_FILE_SIZE = 1 * 1024 * 1024 * 1024 // 1GB = 1 * 1024 * 1024 * 1024 bytes
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          // 禁止不能识别的附件类型
          if (file.name) {
            let str = file.name.split('.')
            if (str[str.length - 1] == 'zip') {
              // 判断文件大小是否超过 1GB
              if (file.size > MAX_FILE_SIZE) {
                this.$message.error(`${file.name} 的文件大小超过了 1GB，请选择一个更小的文件!`)
                document.body.removeChild(input)
                return
              }
              var form = new FormData()
              form.append('file', file)
              form.append('projectId', this.options.id)
              if (id) {
                form.append('id', id)
              }
              try {
                this.loading = true
                const res = await this.$api.project.projectFileUpload(form)
                this.init()
                this.loading = false
                this.$message.success('上传成功')
              } catch (error) {
                this.loading = false
                this.$message.error('上传失败')
              }
            } else {
              this.$message.error('请上传zip格式的文件')
            }
          }
        }
        document.body.removeChild(input)
      })

      input.click()
    },
    async init() {
      this.loading = true
      let { data = [] } = await this.$api.project.projectFileStructure(this.options.id)
      this.treeData = data
      await this.$nextTick()
      if (this.atPresentlInfo.id) {
        const info = this.getAtPresentId(data, this.atPresentlInfo.id)
        if (info && this.$refs.tree) {
          // 设置树形组件的当前节点
          this.$refs.tree.setCurrentKey(info.id)
          // 获取树节点并同步展开状态
          const treeNode = this.$refs.tree.getNode(info.id)
          if (treeNode) {
            // 递归展开父节点直到最上层
            let currentNode = treeNode
            while (currentNode && currentNode.parent) {
              currentNode = currentNode.parent
              if (currentNode.expanded === false) {
                currentNode.expanded = true
              }
            }
            // 设置当前节点展开
            treeNode.expanded = true
          }
        }

        if (info.isDirectory) {
          this.tableData = info.children
          this.atPresentlInfo = info.deepClone()
          this.fileName = info.fileName
        } else {
          const item = this.getAtPresentId(data, this.atPresentlInfo.id)
          this.tableData = [item]
        }
      } else {
        this.tableData = data
      }
      this.loading = false
    },
    getAtPresentId(arr, id = '') {
      let atPresentInfo = {}
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == id) {
          atPresentInfo = arr[i]
          break
        } else if (arr[i].children && arr[i].children.length > 0) {
          atPresentInfo = this.getAtPresentId(arr[i].children, id)
        }
      }
      return atPresentInfo
    },
    rowClick(row, column, event) {
      if (column?.label === '文件名称') {
        if (row.isDirectory) {
          this.isStatistics = true
          this.tableData = row.children || []
        } else {
          this.isStatistics = false
          this.tableData = [row]
        }
        // 切换表格行的展开状态
        this.$refs.table.toggleRowExpansion(row)
        // 设置树形组件的当前节点
        this.$refs.tree.setCurrentKey(row.id)
        // 获取树节点并同步展开状态
        const treeNode = this.$refs.tree.getNode(row.id)
        if (treeNode) {
          treeNode.expanded = true
        }
        this.atPresentlInfo = row.deepClone()
        this.fileName = row.fileName
      }
    },
    // table展开/关闭
    expandChange(row, expanded) {
      if (row.isDirectory) {
        this.isStatistics = true
        this.tableData = row.children || []
      } else {
        this.isStatistics = false
        this.tableData = [row]
      }
      if (row.id) {
        // 设置树形组件的当前节点
        this.$refs.tree.setCurrentKey(row.id)
        // 获取树节点并同步展开状态
        const treeNode = this.$refs.tree.getNode(row.id)
        if (treeNode) {
          treeNode.expanded = true
        }
        this.atPresentlInfo = row.deepClone()
        this.fileName = row.fileName
      }
    },
    // tree展开
    handleNodeExpand(row, node, event) {
      if (row.isDirectory) {
        this.isStatistics = true
        this.tableData = row.children || []
      } else {
        this.isStatistics = false
        this.tableData = [row]
      }
      this.atPresentlInfo = row.deepClone()
      this.fileName = row.fileName
    },
    handleNodeClick(row, node, event) {
      if (row.children && row.children.length == 0) {
        if (row.isDirectory) {
          this.isStatistics = true
          this.tableData = []
        } else {
          this.isStatistics = false
          this.tableData = [row]
        }
        this.atPresentlInfo = row.deepClone()
        this.fileName = row.fileName
      }
    },
  },

  beforeDestroy() {
    this.isStatistics = false
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  .file-content {
    width: 100%;
    height: calc(100vh - 253px);
    display: flex;
    .file-left {
      width: 256px;
      height: 100%;
      background-color: #ffffff;
      margin-right: 10px;
      padding: 10px 0px;
      overflow-y: auto;
    }
    .file-right {
      width: calc(100% - 266px);
      height: 100%;
      overflow-y: auto;
    }
  }
}
.text_adddocument_Bgc {
  background-color: #78c950;
  color: #fff;
  padding: 5px 5px;
}
.text_addFile_Bgc {
  background-color: #6391f3c2;
  color: #fff;
  padding: 5px 5px;
}
.text_addZip_Bgc {
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
.text_download_Bgc {
  background-color: #3ed5f0;
  color: #fff;
  padding: 5px 5px;
}
.text_Edit_Bgc {
  background-color: #e6a23c;
  color: #fff;
  padding: 5px 5px;
}
.text_Remove_Bgc {
  background-color: #f78888;
  color: #fff;
  padding: 5px 5px;
}
</style>
